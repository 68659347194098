import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./LotModal.css";
import JifImg from "../../../../assets/images/img.jfif";

const LotModal = ({ isOpen, lot, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [bidAmount, setBidAmount] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  // console.log(lot, "lot")

  useEffect(() => {
    if (lot) {
      const initialBidAmount =
        lot?.highest_bidder?.amount ||
        // lot?.estimate_from || 0;
        lot?.estimate_from || 0;
      setBidAmount(initialBidAmount);
    }
  }, [lot]);

  if (!isOpen || !lot) return null;

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % lot.images.length);
  };

  const handlePrevious = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + lot.images.length) % lot.images.length
    );
  };

  const handleBidChange = (e) => {
    const value = parseFloat(e.target.value);
    const minBidAmount = lot?.auction.bid_increments[0]?.min_amount || 0;

    if (value >= bidAmount && value >= minBidAmount) {
      setBidAmount(value);
    } else {
      toast.warning("Bid amount cannot be decreased.");
    }
  };

  const handleIncrement = () => {
    const incrementAmount =
      lot?.auction?.bid_increments[0]?.increment_amount || 0;
    const newBidAmount = Math.round((bidAmount + incrementAmount) * 100) / 100;
    setBidAmount(newBidAmount);
  };

  const handleConfirmBid = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirm = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      // console.error("Access token not found in localStorage.");
      toast.error("Please log in to confirm the bid.");
      return;
    }

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/bidding/lot-bid`,
        {
          lotId: lot.hash_id,
          bidAmount,
        },
        { headers }
      );

      if (response.status === 200 && response.data.status === "success") {
        toast.success(response?.data?.message || "Bid confirmed successfully!");
        setShowConfirmDialog(false);
        window.location.reload();
        onClose();
      } else {
        toast.error("Unexpected response status.");
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error(
          error.response.data.message ||
          "Error confirming bid. Please try again."
        );
      } else {
        toast.error("Error confirming bid. Please try again.");
      }
      console.error("Error confirming bid:", error);
    }
  };

  const handleCancel = () => {
    setShowConfirmDialog(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-contents">
        <button className="modal-close" onClick={onClose}>
          <i class="fa-solid fa-xmark"></i>
        </button>
        <div className="modal-bodys">
          <div className="modal-image-slider col-md-5">
            {lot.images.length > 0 ? (
              <>
                <button
                  className="slider-button-modal"
                  onClick={handlePrevious}
                >
                  <i class="fa-solid fa-angle-left text-base"></i>
                </button>
                <div className=" flex w-5/6 h-full items-center justify-center">
                  <img
                    src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${lot.images[
                      currentImageIndex
                    ]?.image_path?.replace(/^\//, "")}`}
                    alt={lot?.title}
                    className="modal-image min-w-full min-h-full object-contain "
                    loading="lazy"
                  />
                </div>
                <button className="slider-button-modal" onClick={handleNext}>
                  <i class="fa-solid fa-angle-right text-base"></i>
                </button>
              </>
            ) : (
              <div className=" flex w-5/6 h-full items-center justify-center">
                <img
                  src={JifImg}
                  alt="Fallback"
                  className="modal-image min-w-[230px] w-full object-cover"
                  width={50}
                  height={100}
                  loading="lazy"
                />
              </div>
            )}
          </div>
          <div className="modal-details col-md-7">
            <h3>{lot.title}</h3>
            <p className="m-0">
              <b>High Bid:</b> {lot?.highest_bidder?.amount} USD
            </p>
            <p className="m-0 text-center">
              {lot?.auction?.location} | {lot?.auction?.city}
            </p>

            <p className="m-0 text-center">
              {new Date(lot?.auction?.start_date).toLocaleDateString()} -
              {new Date(lot?.auction?.end_date).toLocaleDateString()}
            </p>

            <div className="bid-input-container ">
              <input
                type="number"
                value={bidAmount}
                onChange={handleBidChange}
                min={lot?.auction?.bid_increments[0]?.min_amount || 0}
                step={lot?.auction?.bid_increments[0]?.increment_amount || 1}
                className="bid-input p-0 m-0 py-1 px-1 w-full h-full "
              />
              {/* <button onClick={handleIncrement} className="increment-button">+</button>  */}
            </div>
            <button onClick={handleConfirmBid} className="confirm-bid-button">
              Confirm Bid
            </button>
          </div>
        </div>
      </div>
      {showConfirmDialog && (
        <div className="confirm-dialog">
          <p>Are you sure you want to place this bid?</p>
          <button onClick={handleConfirm} className="confirm-dialog-button">
            Yes
          </button>
          <button onClick={handleCancel} className="confirm-dialog-button">
            No
          </button>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default LotModal;
