import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import "./ForgotPasswordModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const ForgotPasswordModal = ({ show, onHide }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleResetPassword = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
         `${process.env.REACT_APP_API_URL}/forgot-password`,
        { email }
      );
      // console.log('Password reset email sent:', response.data);      
      toast.success("Password reset email sent successful!");
      setEmail("");
      onHide();
    } catch (err) {
      // console.error('Error sending password reset email:', err);
      toast.error("Failed to send password reset email. Please try again", err);
      setError("Failed to send password reset email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      className="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>STEP 1: ENTER EMAIL ADDRESS FOR YOUR ACCOUNT</h3>
          <p className="email-address">
            (An email will be sent to this address with a link to reset your
            password)
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="forgot-modal">
          <Form>
            <Row className="mb-3 align-items-center">
              <Col md={12}>
                <div className="d-flex">
                  <FontAwesomeIcon
                    className="register-icons me-3"
                    icon={faEnvelope}
                    size="lg"
                  />
                  <Form.Control
                    type="email"
                    className="register-email"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
              </Col>
            </Row>
            {/* Show error message if any */}
            {error && <p className="text-danger">{error}</p>}{" "}
            <div className="d-grid gap-2">
              <Button
                variant="primary"
                size="lg"
                onClick={handleResetPassword}
                disabled={loading}
              >
                {loading ? "Sending..." : "Reset Password"}
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPasswordModal;
