import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import "./AuctionDetail.css";
import JifImg from "../../../assets/images/Woodburn1.png";
import Magnifier from "react-magnifier";
import { Helmet } from "react-helmet";

const AuctionDetail = () => {
  const { hash_id } = useParams();
  const [auctionData, setAuctionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const url = window.location.href;

  // Fetch auction data
  useEffect(() => {
    const fetchAuctionData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get/auctions/${hash_id}`
        );
        const { auction } = response.data;
        setAuctionData(auction);
        console.log(auction, "All lot hash_ids");
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAuctionData();
  }, [hash_id]);

 
  // Helper function to calculate days left
  const calculateDaysLeft = (endDate) => {
    const timeLeft = new Date(endDate) - new Date();
    return timeLeft > 0 ? Math.floor(timeLeft / (1000 * 60 * 60 * 24)) : 0;
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }

  if (!auctionData) {
    return <div className="no-data">No auction data found</div>;
  }

  const lots = auctionData.lots || [];
  const firstSixLots = lots.slice(0, 6);
  const remainingLots = lots.slice(6);
  // console.log(lots, "lots")

  return (
    <>
      <Helmet>
        <title>{auctionData.meta_title || auctionData.name} - AuctionLive</title>
        <meta name="description" content={auctionData.meta_description} />
        <meta property="og:title" content={auctionData.meta_title} />
        <meta property="og:description" content={auctionData.meta_description} />
        <meta property="og:url" content={url} />
        <link rel="canonical" href={url} />
      </Helmet>

      <div className="container pt-3">
        <div className="row">
          <div className="col-md-12">
            <div className="lots-row">
              {firstSixLots.map((lot) => (
                <div className="lot-item" key={lot.hash_id}>
                  <Link to={`/lots/${lot.hash_id}`} style={{ textDecoration: "none" }}>
                    <div className="lot-title text-black">
                      {lot.lot_number} | {lot.title}
                    </div>     
                      <Magnifier  
                        src={
                          lot?.lot_first_image?.image_path
                            ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${lot?.lot_first_image?.image_path}`
                            : JifImg
                        }
                        alt={lot.title}
                        loading="lazy"
                      />

                    <div className="lot-details">
                      <p className="text-black mb-0">
                        High Bid:{" "}
                        {lot.highest_bidder?.status === "Winning"
                          ? lot.highest_bidder.amount
                          : "0"}
                      </p>
                      {(lot.highest_bidder?.amount || 0) < lot.reserve_price && (
                        <p className="reserve-text text-danger font-semibold mb-0">
                          Reserve {lot.reserve_price}
                        </p>
                      )}
                      <span className="font-semibold">
                        {calculateDaysLeft(auctionData.end_date)} days left
                      </span>
                      <p className="text-black">
                        Bids: {lot.bids.length || 0}
                      </p>
                      <button className="bid-button">View Detail</button>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {remainingLots.length > 0 && (
              <div className="lots-box">
                {remainingLots.map((lot) => (
                  <div className="lot-box-item" key={lot.hash_id}>
                    <Link to={`/lots/${lot.hash_id}`}>
                      <div className="lot-box-title">
                        <p>Lot Number: {lot.lot_number}</p>
                        <p>{lot.title}</p>
                      </div>
                      <img
                        src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${lot.images[0]?.image_path || ""}`}
                        alt={lot.title}
                        className="lot-box-image"
                        loading="lazy"
                      />
                      <div className="lot-box-details">
                        <p>High Bid: {lot.high_bid || "N/A"}</p>
                        <p>Winning Value: {lot.winning_value || "N/A"}</p>
                        <button className="bid-button">Place Bid</button>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuctionDetail;
