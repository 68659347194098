import React, { useEffect, useState } from "react";
// import hammer from "../../../assets/images/AdobeStock_607256595_Preview.jpeg";
import "./Help.css";
import { Helmet } from "react-helmet";
import axios from "axios";

const Help = () => {
  window.scroll(0, 0);
  const [info, setinfo] = useState();
  const url = window.location.href;

  const fetchinfo = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/info/help`)
    setinfo(response.data.data)
    // console.log(response.data.data, "info")
  }
  useEffect(() => {
    fetchinfo()
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${info?.meta_title || info?.name}`}</title>
        <meta
          name="description"
          content={`${info?.meta_description || info?.name}`}
        />
        <meta
          property="og:title"
          content={`${info?.meta_title || info?.name}`}
        />
        <meta
          property="og:description"
          content={`${info?.meta_description || info?.name}`}
        />
        <meta
          property="og:url"
          content={url}
        />
        <link
          rel="canonical"
          href={url}
        />
      </Helmet>
      <div className="container mt-3 mb-3">
        <p dangerouslySetInnerHTML={{ __html: info?.description || "" }} />
      </div>
      {/* <div className="container">
        <div className="row pt-5">
          <h1 className="px-5 text-start" style={{ color: "#2695ff", fontSize: "28px" }}>
            Auctions Help
          </h1>
          <h5 className="text-start px-5 pt-3">
            <b>How do I get real help from Auctionslive.net?</b>
          </h5>
          <p className="text-start pt-3 px-5">
            If you can't find the information you need in our Help articles, and
            would like to get in touch with us, we're here to help. To improve
            your help experience, please Email us at{" "}
            <a href="mailto:auctionslivenet@yahoo.com">
              auctionslivenet@yahoo.com
            </a>
            <br />
            <span>651-334-8312 customer service .</span>
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>Start Selling</b>{" "}
          </h5>
          <p className="text-start  px-5">
            Whether you’re an auctioneer seeking to upload lots and hold
            auctions through Auctions or an individual seller looking to consign
            items for sale, we make the entire process as easy as possible.
          </p>
          <h5 className="text-start px-5 pt-1">
            <b>How do I make sure I win an auction on Auctionslive.net?</b>{" "}
          </h5>
          <h5 className="text-start px-5 pt-1">
            <b>Bide your time. </b>{" "}
          </h5>
          <p className="text-start  px-5">
            You stand a greater chance of getting the item by placing your
            highest bid in the closing seconds. If an auction listing has a
            reserve price, bid up to that amount as early as possible, so other
            bidders aren't attracted by the low starting price. Try bidding an
            uneven amount.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>How to win the last second bid on Auctionslive.net?</b>
          </h5>
          <p className="text-start  px-5">
            The best Auctionslive.net bidding strategy is to bid once, bid as
            late as possible and bid the absolute most you will pay for that
            item. Don't bid in even dollar amounts. If the highest you can pay
            is $10, then make it $10.47. By bidding in the last few seconds, you
            don't give other bidders the chance to react to your bid.
          </p>

          <h5 className="text-start px-5 pt-3">
            <b>
              Why do I always get outbid on Auctionslive.net at the last second?
            </b>
          </h5>
          <p className="text-start  px-5">
            Someone might be sniping to win or they may have put in a high proxy
            bid earlier in the auction so when you placed your bid in the last
            few seconds, their higher proxy bid got upped to outbid you.
          </p>

          <h5 className="text-start px-5 pt-3">
            <b>Why does Auctionslive.net keep saying I've been outbid?</b>
          </h5>
          <p className="text-start  px-5 pt-2">
            Why was I immediately outbid? If you are outbid immediately after
            placing a bid, it's likely that another bidder is using automatic
            bidding and has a maximum limit higher than yours. You'll need to
            increase your maximum limit in order to be the highest bidder.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>“Forbidden “ What is shill bidding?</b>{" "}
          </h5>
          <p className="text-start  px-5 ">
            Among the types of auction fraud, the most prominent is Shill
            bidding. Shill bidding is intentionally fake bidding by a seller on
            his/her own auction to inflate the final price. This can be
            accomplished either by the seller himself/herself or by someone
            colluding with the seller to place fake bids on his/her behalf.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>Can someone help me sell on Auctionslive.net?</b>{" "}
          </h5>
          <p className="text-start  px-5 ">
            Auction companies on our site are experienced sellers who will sell
            your items on Auctionslive.net for a fee. You may contact them
            directly from our site or reach out to us we would be happy to set
            you up with an auction company at no cost to you.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>Why does the same person keep bidding on Auctionslive.net?</b>{" "}
          </h5>
          <p className="text-start  px-5 ">
            It is perfectly normal do not worry. A bidder cannot bid against
            themselves, so they may be placing higher bids, but those will only
            show once someone else places an equal or higher bid.{" "}
          </p>

          <h5 className="text-start px-5 pt-3">
            <b>
              What does it mean on Auctioneslive.net when it says you have the
              Hibid?
            </b>{" "}
          </h5>
          <p className="text-start  px-5 ">
            Highest bid received (but not cancelled/retracted) before the
            auction ends win, but the price is set by the high bid of the SECOND
            highest bidder (again, as of what is received but not
            cancelled/retracted before the end) so it is not necessary to take
            into account what others have bid, what you guess they have bid.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>
              Who wins if two people bid the same amount on Auctionslive.net?
            </b>{" "}
          </h5>
          <p className="text-start  px-5 ">
            The highest bid ALWAYS wins the auction. If 2 people bid exactly the
            same amount, the one that did it first wins. Bid your highest amount
            and if you are first you will be the winner.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>Does Auctionslive.net charge a fee?</b>{" "}
          </h5>
          <p className="text-start  px-5 ">
            auctionslive.net Does charge a fee to sell on its platform. In some
            instances, sellers choose to pass this cost on to buyers in the form
            of a Buyer's Premium.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>How does Auctionslive.net work?</b>{" "}
          </h5>
          <p className="text-start  px-5 ">
            auctionslive.net is a Proxibid platformuse the ascending-bid method,
            which requires a minimum of two bidders. The auctioneer calls for an
            opening price and buyers bid competitively until no new bids are
            placed. The auction is over when the auctioneer calls “sold” or
            drops the hammer.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>What happens after you win an auction bid?</b>{" "}
          </h5>
          <p className="text-start  px-5 ">
            When you place the winning bid, you talk with the auctioneer to
            provide your contact information, And form of payment. How you want
            to be title if its for example as an individual, a partnership, an
            LLC or a trust) and pay for item or items. The auctioneer has the
            name of the trustee, to whom you sign over your cashier's checks.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>What does reserve not met mean on Auctionslive.net?</b>{" "}
          </h5>
          <p className="text-start  px-5 ">
            As auction events on auctionslive.net are controlled by different
            sellers and auction houses, reserves can vary from event to event.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>Can seller cancel auction after I won?</b>{" "}
          </h5>
          <p className="text-start  px-5 ">
            While a seller has the right to withdraw, postpone or accept offers
            on a property before the auction itself, once a bid has been
            accepted the contract is legally binding, they are no longer able to
            cancel the sale.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>What happens if I win a bid and don't pay?</b>{" "}
          </h5>
          <p className="text-start  px-5 ">
            Under general contract law, your bid is a binding offer and at the
            completion of the sale a binding contract exists. Failure to pay
            would constitute breach of contract for which you can be sued for
            the bid price
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>Make Your Auction A Featured Auction</b>{" "}
          </h5>
          <p className="text-start  px-5 ">
            Featured Auctions are the first sales you’ll see at the top of the
            Auctions homepage and on Auctions's state, province, and country
            portal sites. Featured Auctions also appear at the top of Auctions's
            weekly email newsletter, providing extra exposure for those sales to
            Auctions bidders everywhere.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>Find Auctions Near You</b>{" "}
          </h5>
          <p className="text-start px-5 ">
            At the top of any page on auctionslive.com, click the Find Auctions
            menu and select All Auctions (Map). On the interactive map, you can
            zoom and out using the plus and minus sign icons, or click one of
            the dots to see auctions in a specific area.
          </p>
          <p className="text-start px-5 ">
            Searching by state, province, or region? Click Auctions By State,
            choose your area, and select a category. Then, to search ONLY in
            your area, use the category menu that appears on the left side of
            the page. To search ALL of Auctions, use the menus at the top of the
            page
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>Find Auctions Closing Soon</b>{" "}
          </h5>
          <p className="text-start px-5 ">
            Under the Find Auctions menu, click Auctions Closing Soon to view
            sales in progress and about to close. You can further refine your
            view by choosing between the Auctions, Map, and Lots options at the
            top right of the screen.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>Proxibid, Biddable, Webcast & Online-Only Auctions</b>{" "}
          </h5>
          <p className="text-start px-5">
            You can also filter the types of auctions you want to see based on
            auction type. Click the Find Auctions menu at the top of the page
            and select one of these options:
          </p>
          <p className="text-start px-5">
            <b>Biddable -</b> All sales with bidding or pre-bidding currently
            open.
          </p>
          <p className="text-start px-5">
            <b>Webcast -</b> Live sales with both online and in-person floor
            bidders for an expanded pool of buyers.
          </p>
          <p className="text-start px-5 ">
            <b>Online-Only Auction -</b> Sales conducted entirely online,
            providing a safe, secure way for bidders to participate remotely.
          </p>
          <h5 className="text-start px-5 pt-3">
            <b>Stay Up-To-Date</b>{" "}
          </h5>
          <p className="text-start px-5">
            Auctionslive.net once-a-week email newsletter keeps you in the know
            about the latest auctions.
          </p>
          <p className="text-start px-5">
            To sign up for it, simply click the Join Our Mailing List link or
            menu item at the top of the page.
          </p>
          <img src={hammer} alt="hammer" className="hammer-img img-fluid" loading="lazy" />
        </div>
      </div> */}
    </>
  );
};

export default Help;
