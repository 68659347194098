import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Spinner,
  Alert,
  Pagination,
  Modal,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./EmailEdit.css";
import ViewEmail from "./ViewEmail";
import EmailEditModal from "./EmailEditModal";

const EmailList = () => {
  const [emails, setEmails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [formData, setFormData] = useState({ name: "", subject: "", body: "" });
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [emailToDelete, setEmailToDelete] = useState(null);

  useEffect(() => {
    fetchEmails();
  }, []);

  const fetchEmails = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found.");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/emails`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      if (response.data.status === "success") {
        if (response.data.data.length === 0) {
          setNoData(true);
        } else {
          setEmails(response.data.data);
          setNoData(false);
        }
      } else {
        toast.error(`Failed to fetch emails: ${response.data.message}`);
        setNoData(true);
      }
    } catch (error) {
      toast.error("Error fetching emails");
      setNoData(true);
    } finally {
      setLoading(false);
    }
  };

  const handleViewEmail = (email) => {
    setCurrentEmail(email);
    setShowViewModal(true);
  };

  const handleEditEmail = (email) => {
    setCurrentEmail(email);
    setFormData({
      name: email.name,
      subject: email.subject,
      body: email.body,
    });
    setShowModal(true);
  };

  const handleDeleteEmail = (email) => {
    setEmailToDelete(email);
    setShowConfirmDeleteModal(true);
  };

  const confirmDeleteEmail = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      toast.error("Access token not found.");
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/emails/delete/${emailToDelete.hash_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      if (response.data.status === "success") {
        fetchEmails();
        setShowConfirmDeleteModal(false);
        setEmailToDelete(null);
        toast.success("Email deleted successfully!");
      } else {
        toast.error(`Failed to delete email: ${response.data.message}`);
      }
    } catch (error) {
      toast.error("Error deleting email");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentEmail(null);
  };

  const handleCloseViewModal = () => {
    setShowViewModal(false);
    setCurrentEmail(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setEmailToDelete(null);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditorChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      body: value,
    }));
  };

  const handleSaveChanges = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      toast.error("Access token not found.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/emails/update/${currentEmail.hash_id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      if (response.data.status === "success") {
        fetchEmails();
        handleCloseModal();
        toast.success("Email updated successfully!");
      } else {
        toast.error(`Failed to update email: ${response.data.message}`);
      }
    } catch (error) {
      toast.error("Error updating email");
    }
  };

  const stripHtmlTags = (html) => html.replace(/<\/?[^>]+>/gi, "");

  const truncateText = (text, wordLimit = 50, charLimit = 200) => {
    const strippedText = stripHtmlTags(text);
    const words = strippedText.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    if (strippedText.length > charLimit) {
      return strippedText.slice(0, charLimit) + "...";
    }
    return strippedText;
  };

  const totalPages = Math.ceil(emails.length / itemsPerPage);
  const currentItems = emails.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginationItems = [];
  for (let number = 1; number <= totalPages; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <div className="container email-list">
      <h1 className="mt-3">Email Templates</h1>
      {loading ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : noData ? (
        <Alert variant="warning" className="mt-5">
          No emails found.
        </Alert>
      ) : (
        <>
          <Table striped bordered hover className="email-table mt-5 mb-5">
            <thead>
              <tr>
                <th>Name</th>
                <th>Subject</th>
                <th>Body</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((email) => (
                <tr key={email.hash_id}>
                  <td>{email.name}</td>
                  <td>{email.subject}</td>
                  <td>{truncateText(email.body)}</td>
                  <td>
                    <Button
                      variant="link"
                      className="me-2"
                      onClick={() => handleViewEmail(email)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                    <Button
                      variant="link"
                      className="me-2"
                      onClick={() => handleEditEmail(email)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button
                      variant="link"
                      onClick={() => handleDeleteEmail(email)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination className="justify-content-center">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {paginationItems}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </>
      )}
      {currentEmail && (
        <EmailEditModal
          showModal={showModal}
          handleClose={handleCloseModal}
          formData={formData}
          handleFormChange={handleFormChange}
          handleEditorChange={handleEditorChange}
          handleSaveChanges={handleSaveChanges}
        />
      )}
      {currentEmail && (
        <ViewEmail
          show={showViewModal}
          email={currentEmail}
          handleClose={handleCloseViewModal}
        />
      )}
      {emailToDelete && (
        <Modal
          show={showConfirmDeleteModal}
          onHide={handleCloseConfirmDeleteModal}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span className="text-black">
              Are you sure you want to delete this email?
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseConfirmDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={confirmDeleteEmail}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <ToastContainer />
    </div>
  );
};

export default EmailList;
