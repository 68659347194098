import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import HammerIcon from "@mui/icons-material/Gavel";
import { Box } from "@mui/material";
import axios from "axios";
import AddAuctionModal from "./AddAuctionModal";
import EditAuctionModal from "./EditAuctionModal";
import { Link } from "react-router-dom";
import './AddAuctionModal.css'
import MUIDataTable from "mui-datatables";
import moment from "moment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

let selectedRowIds = [];

function DashboardAuctioner() {
  const [rows, setRows] = useState([]);
  const [editData, setEditData] = useState(null);
  const [newAuctionData, setNewAuctionData] = useState({
    name: "",
    archived: "",
    endDate: "",
  });
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);


  useEffect(() => {
    fetchAuctions();
  }, []);

  const fetchAuctions = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get/auctions`,
        { headers }
      );
      setRows(response.data.data);
      // console.log(response.data.data, "get auction fsafs")
    } catch (error) {
      console.error("Error fetching auctions:", error);
    }
  };


  const handleEdit = (hashId) => {
    const editedAuction = rows.find((row) => row.hash_id === hashId);
    setEditData(editedAuction);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleChangeAdd = (e) => {
    setNewAuctionData({ ...newAuctionData, [e.target.name]: e.target.value });
  };

  const handleSaveAdd = () => {
    if (!rows.find((row) => row.code === newAuctionData.code)) {
      setRows([...rows, newAuctionData]);
    }

    setOpenAdd(false);
    setNewAuctionData({
      name: "",
      archived: "",
      endDate: "",
    });
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  }

  const handleDelete = async (hash_Id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      });
      if (result.isConfirmed) {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found.");
          return;
        }
        const headers = {
          Authorization: `Bearer ${accessToken}`
        };
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/delete/auctions/${hash_Id}`,
          { headers }
        );
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success"
        });
        fetchAuctions();
        // console.log(`Auction with hashId ${hash_Id} deleted successfully`);
      }
    } catch (error) {
      console.error("Error deleting auction:", error.message);
    }
  };


  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Link to={`/auctions/${tableMeta.rowData[7]}`} style={{ textDecoration: "none" }}>
            <div className="pt-3 pb-3">{value}</div>
          </Link>
        ),
      },
    },
    {
      name: "start_date",
      label: "Start Date",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Link to={`/auctions/${tableMeta.rowData[7]}`} style={{ textDecoration: "none" }}>
            <div className="pt-3 pb-3">{value}</div>
          </Link>
        ),
      },
    },
    {
      name: "end_date",
      label: "End Date",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Link to={`/auctions/${tableMeta.rowData[7]}`} style={{ textDecoration: "none" }}>
            <div className="pt-3 pb-3">{value}</div>
          </Link>
        ),
      },
    },
    {
      name: "state_name",
      label: "State",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Link to={`/auctions/${tableMeta.rowData[7]}`} style={{ textDecoration: "none" }}>
            <div className="pt-3 pb-3">{value}</div>
          </Link>
        ),
      },
    },
    {
      name: "country_name",
      label: "Country",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Link to={`/auctions/${tableMeta.rowData[7]}`} style={{ textDecoration: "none" }}>
            <div className="pt-3 pb-3">{value}</div>
          </Link>
        ),
      },
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "bid status",
      label: "Bid Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          const endDate = moment(tableMeta.rowData[2]);
          const currentDate = moment();
          const bidStatus = endDate.isBefore(currentDate) ? "Closed" : "Open";
          return bidStatus;
        },
      },
    },
    {
      name: "hash_id",
      label: "Actions",
      options: {
        customBodyRender: (value) => (
          <>
            <IconButton sx={{ color: "green" }} onClick={() => handleEdit(value)} >
              <EditIcon />
            </IconButton>
            <IconButton sx={{ color: "red" }} onClick={() => handleDelete(value)}>
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    },

  ];

  const selected = (rowIds) => {
    selectedRowIds = rowIds;
    // console.log(rowIds, "new select");
    setIsRowSelected(rowIds.length > 0)
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "multiple",
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    isRowSelectable: (dataIndex) => true,
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      const rowIds = allRowsSelected.map(row => data[row.dataIndex].hash_id);
      // console.log("Selected Row IDs:", rowIds);
      selected(rowIds);
    },
  };

  const data = rows.map((row) => ({
    name: row.name,
    start_date: row.start_date,
    end_date: row.end_date,
    state_name: row.state?.name,
    country_name: row.country?.name,
    status: row.status,
    hash_id: row.hash_id,
  }));


  const handleSend = () => {
    // console.log(selectedRowIds, "auction id");
    axios.post(`${process.env.REACT_APP_API_URL}/bid-complete-mail`, { auction_id: selectedRowIds })
      .then((result) => {
        // console.log(result)
        toast.success("Email sent successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong", error);
      });
  };




  return (
    <>
      <div className="container">
        <div className="row px-3">
          <Box
            mt={2}
            mb={2}
            p={2}
            boxShadow={3}
            bgcolor="white"
            style={{ background: "#fff" }}
          >
            <Grid container spacing={2} alignItems="center" className="flex items-center justify-between">
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                }}
              >
                <HammerIcon style={{ marginRight: "8px" }} />
                Auctions
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                }}
              >
                <IconButton onClick={handleOpenAdd} className="border-0 rounded-lg">
                  <AddIcon style={{ marginRight: "8px" }} />
                  New Auction
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          <div>
            {isRowSelected && (
              <button
                className="btn btn-primary"
                onClick={handleSend}
                style={{ marginBottom: '16px' }}
              >
                Send Email
              </button>
            )}

            <MUIDataTable
              data={data}
              columns={columns}
              options={options}
              className="mb-5"
            />
          </div>
          <AddAuctionModal
            open={openAdd}
            onClose={handleCloseAdd}
            handleChange={handleChangeAdd}
            handleSave={handleSaveAdd}
            newAuctionData={newAuctionData}
            fetchAuctions={fetchAuctions}
          />
          <EditAuctionModal
            open={openEdit}
            close={handleCloseEdit}
            editData={editData}
            fetchAuctions={fetchAuctions}
          />
        </div>
      </div>
    </>
  );
}

export default DashboardAuctioner;
