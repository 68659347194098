import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Form, Modal, Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setEditModal] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryType, setNewCategoryType] = useState("main");
  const [imageFile, setImageFile] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const navigate = useNavigate();

  const fetchCategories = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      setLoading(false);
      return;
    }
    const apiHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categories`,
        apiHeaders
      );
      const mainCategories = response.data.data.filter(
        (category) => category.category_type === "main"
      );
      setCategories(mainCategories);
      // console.log(mainCategories, "header category")
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCreateCategory = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }
    const apiHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("name", newCategoryName);
    formData.append("category_type", newCategoryType);
    formData.append("parent_id", "");
    if (imageFile) {
      formData.append("image", imageFile);
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/categories/create`,
        formData,
        apiHeaders
      );
      toast.success("Category added successfully!");
      fetchCategories();
      setShowModal(false);
      setNewCategoryName("");
      setNewCategoryType("main");
      setImageFile(null);
    } catch (error) {
      console.error("Error creating category:", error);
      toast.error("Error adding category. Please try again.");
    }
  };

  const handleViewCategory = (hash_id) => {
    navigate(`/super/admin/categories/${hash_id}`);
  };

  const handleShowDeleteConfirm = (hash_id) => {
    setCategoryToDelete(hash_id);
    setDeleteConfirmModal(true);
  };

  const handleDeleteCategory = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }
    const apiHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      },
    };
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/categories/delete/${categoryToDelete}`,
        apiHeaders
      );
      toast.success("Category deleted successfully!");
      fetchCategories();
      setDeleteConfirmModal(false);
      setCategoryToDelete(null);
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error("Error deleting category. Please try again.");
    }
  };

  const handleShowEdit = async (id) => {
    setCategoryToEdit(id);
    // console.log(id, "hash id");
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        console.error("Access token not found in localStorage.");
        return;
      }
      const apiHeaders = {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/categories/edit/${id}`,
        { headers: apiHeaders }
      );
      const categoryData = response.data.data;
      // console.log(categoryData, "category data");
      formik.setFieldValue("name", categoryData.name);
      formik.setFieldValue("is_active", categoryData.is_active);
      setCurrentImage(
        `https://api.auctionslive.net/storage/${categoryData.media[0]?.file_path}`
      );
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
    setEditModal(true);
  };

  const handleImageChangeedit = (event) => {
    const file = event.target.files[0];
    if (file) {
      formik.setFieldValue("media", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      media: [],
      is_active: "",
      category_type: "main",
      parent_id: "",
    },
    onSubmit: async (values) => {
      // console.log(values, "form values");
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found in localStorage.");
          return;
        }

        const apiHeaders = {
          Authorization: `Bearer ${accessToken}`,
          "ngrok-skip-browser-warning": "69420",
        };

        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("is_active", parseFloat(values.is_active, 10));
        formData.append("category_type", values.category_type);
        formData.append("parent_id", values.parent_id);
        if (values.media) {
          formData.append("image", values.media);
        }
        // if (values.media && values.media instanceof File) {
        //   formData.append("image", values.media); // Append new file if present
        // } else if (values.media && values.media.id) {
        //   // If no new file, send existing media ID to retain it on the server
        //   formData.append("existing_media_id", values.media.id);
        // }
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/categories/update/${categoryToEdit}`,
          formData,
          { headers: apiHeaders }
        );

        // console.log("Category updated:", response);
        fetchCategories();
        setEditModal(false);
      } catch (error) {
        console.error("Error updating category:", error);
      }
    },
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCategories = categories.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(categories.length / itemsPerPage);
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const renderPagination = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return (
      <Pagination>
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        />
        {items}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        />
      </Pagination>
    );
  };
  if (loading) {
    return (
      <div className="loader-overlay">
        <div className="spinner-border-custom"></div>
      </div>
    );
  }



  return (
    <div className="container p-3">
      <div className="row pt-3">
        <div className="col-6">
          <h1 className="px-3 pb-3">Categories</h1>
        </div>
        <div className="col-6 ">
          <div className="text-right">
            <Button variant="primary" onClick={() => setShowModal(true)}>
              Create Category
            </Button>
          </div>
        </div>
      </div>
      <Table striped bordered hover responsive className="country-table">
        <thead>
          <tr>
            <th>Category Name</th>
            <th>Type</th>
            <th>Slug</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentCategories.length > 0 ? (
            currentCategories.map((category) => (
              <tr key={category.hash_id}>
                <td>{category.name}</td>
                <td>{category.category_type}</td>
                <td>{category.slug}</td>
                <td>
                  <Button
                    variant={category.is_active === 1 ? "success" : "danger"}
                  >
                    {category.is_active === 1 ? "Enabled" : "Disabled"}
                  </Button>
                </td>
                <td>
                  <Button
                    variant="info"
                    onClick={() => handleViewCategory(category.hash_id)}
                  >
                    View
                  </Button>
                  <Button
                    variant="success"
                    className="ml-2"
                    onClick={() => handleShowEdit(category.hash_id)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    className="ml-2"
                    onClick={() => handleShowDeleteConfirm(category.hash_id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No categories available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {renderPagination()}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ width: "100%" }}>
            <Form.Group controlId="formCategoryName">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter category name"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCategoryType">
              <Form.Label>Category Type</Form.Label>
              <Form.Control
                as="select"
                value={newCategoryType}
                onChange={(e) => setNewCategoryType(e.target.value)}
              >
                <option value="main">Main</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formImage">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateCategory}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEdit} onHide={() => setEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
            <Form.Group controlId="formCategoryName">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter category name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </Form.Group>
            {currentImage && (
              <div className="mt-2">
                <img
                  src={currentImage}
                  alt="Current Category"
                  style={{
                    width: "100px",
                    maxHeight: "100px",
                    objectFit: "contain",
                  }}
                  loading="lazy"
                />
              </div>
            )}
            <Form.Group controlId="formImage">
              <Form.Label>Change Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChangeedit}
              />
            </Form.Group>
            {/* <Form.Group controlId="formIsActive">
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="is_active"
                value={formik.values.is_active}
                onChange={(e) =>
                  formik.setFieldValue(
                    "is_active",
                    parseInt(e.target.value, 10)
                  )
                }
              >
                <option value={1}>Enabled</option>
                <option value={0}>Disabled</option>
              </Form.Select>
            </Form.Group> */}
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setEditModal(false)}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={deleteConfirmModal}
        onHide={() => setDeleteConfirmModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-black">
          Are you sure you want to delete this category?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setDeleteConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteCategory}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};
export default Categories;
