import React, { useEffect } from "react";
import { Box, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Editor from "react-simple-wysiwyg";
import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";

function EditBlog({ open, onClose, id, fetchBloglist }) {
  const fatchBlog = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        console.error("Access token not found in localStorage.");
        return;
      }
      const apiHeaders = {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/posts/show/${id}`,
        { headers: apiHeaders }
      );
      // console.log('Blog single:', response.data.data);
      formik.setValues({
        name: response.data.data.name || "",
        description: response.data.data.description || "",
        meta_title: response.data.data.meta_title || "",
        meta_description: response.data.data.meta_description || "",
        image: null,
      });
    } catch (error) {
      console.error("Error updating blog:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fatchBlog();
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      meta_title: "",
      meta_description: "",
      image: null,
    },

    onSubmit: async (values) => {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found in localStorage.");
          return;
        }
        const apiHeaders = {
          Authorization: `Bearer ${accessToken}`,
          "ngrok-skip-browser-warning": "69420",
        };

        const formData = new FormData();
        formData.append("title", values.name);
        formData.append("description", values.description);
        formData.append("meta_title", values.meta_title);
        formData.append("meta_description", values.meta_description);

        if (values.image) {
          formData.append("image", values.image);
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/posts/update/${id}`,
          formData,
          { headers: apiHeaders }
        );

        console.log("Blog updated:", response);
        formik.resetForm();
        toast.success("Blog updated successfully!");
        onClose();
        fetchBloglist();
      } catch (error) {
        console.error("Error updating blog:", error);
        toast.error("Error updating blog");
      }
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("image", file);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h2>Edit Blog</h2>
          <CloseIcon
            onClick={onClose}
            sx={{ fontSize: "20px", cursor: "pointer" }}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            className="mb-4 w-100"
            id="Title"
            label="Title"
            variant="standard"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />

          <Editor
            name="description"
            value={formik.values.description}
            onChange={(e) =>
              formik.setFieldValue("description", e.target.value)
            }
          />

          <TextField
            className="w-100"
            id="meta title"
            label="Meta Title"
            variant="standard"
            name="meta_title"
            value={formik.values.meta_title}
            onChange={formik.handleChange}
          />
          <TextField
            className="w-100"
            id="meta description"
            label="Meta Description"
            variant="standard"
            name="meta_description"
            value={formik.values.meta_description}
            onChange={formik.handleChange}
          />

          <div className="mt-3">
            <h4>Change Image</h4>
            <input type="file" name="image" onChange={handleFileChange} />
            {formik.values.image && (
              <div className="mt-2">
                <img
                  src={URL.createObjectURL(formik.values.image)}
                  alt="Selected"
                  width="200"
                />
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end">
            <button className="btn btn-secondary mt-3 me-2" onClick={onClose}>
            Cancel
            </button>
            <button className="btn btn-primary mt-3" type="submit">
              Update
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}

export default EditBlog;
