import React, { useEffect, useState } from "react";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import { format } from "date-fns";
import { CircularProgress } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Pagesedit from "./Pagesedit";

function Pages() {
  const [editShow, setEditShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [editid, setEditid] = useState(null);

  const fetchBloglist = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      setLoading(false);
      return;
    }
    const apiHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/pages/list`,
        apiHeaders
      );
      const formattedData = response.data.data.map((item) => ({
        ...item,
        created_at: format(new Date(item.created_at), "MMMM dd, yyyy, h:mm a"),
      }));
      setData(formattedData || []);
      // console.log("blog list", response.data.data)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pages:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBloglist();
  }, []);


  const columns = [
    { name: "id", label: "ID" },
    { name: "name", label: "Name" },
    // {
    //   name: "description",
    //   label: "DESCREPTION",
    //   options: {
    //     customBodyRender: (value) => {
    //       if (!value) {
    //         return "";
    //       }
    //       const words = value.split(" ");
    //       const truncatedDescription =
    //         words.length > 10 ? words.slice(0, 10).join(" ") + "..." : value;
    //       return (
    //         <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
    //       );
    //     },
    //   },
    // },
    { name: "created_at", label: "Created At", options: { sort: true } },
    {
      name: "action",
      label: "Actions",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <button
                className="btn btn-sm"
                onClick={() => handleEditBlog(tableMeta.rowData[0])}
              >
                <BorderColorIcon style={{ color: "green" }} />
              </button>
              {/* <button
                className="btn btn-sm"
                onClick={() => handleDeleteBlog(tableMeta.rowData[0])}
              >
                <DeleteIcon style={{ color: "red" }} />
              </button> */}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    download: false,
    print: false,
  };

  const handleEditBlog = (id) => {
    setEditid(id);
    setEditShow(true);
    // console.log(`Edit blog with ID: ${id}`);
  };

  return (
    <>
      <section className="admin-pages">
        <div className="container">
          <div className="row mt-5 px-3">
            <div className="blog-add d-flex justify-content-between align-items-center mb-3">
              <h2>Page List</h2>
            </div>

            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              <MUIDataTable
                data={data.length ? data : []}
                columns={columns}
                options={options}
              />
            )}
          </div>
        </div>
      </section>
      <Pagesedit
        open={editShow}
        onClose={() => setEditShow(false)}
        id={editid}
        fetchBloglist={fetchBloglist}
      />
    </>
  );
}

export default Pages;
