import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import "./Chat.css";
import Rechat from "./Rechat";
import Viewer from "./Viewer";
import Pusher from "pusher-js";
import { toast } from "react-toastify";

const Chat = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const chatContainerRef = useRef(null);
  const currentUser = localStorage.getItem("username");
  const { hash_id } = useParams();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/chat/messages/single/${hash_id}`)
      .then((response) => {
        setMessages(response.data);
        // console.log("Fetched Data:", response.data);
      })
      .catch((error) => {
        console.error("Error fetching messages:", error.response?.data || error.message);
      });
    // Pusher setup
    const pusher = new Pusher("c9cce552f35a285daf3f", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("message");
    channel.bind("MessageEvent", (data) => {
      // console.log("New message received:", data);
      setMessages((prevMessages) => [...prevMessages, data.message]);
    });

    // Cleanup on component unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, []);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!message.trim()) {
      toast.error("Message cannot be empty.");
      return;
    }

    const data = {
      username: currentUser,
      message: message.trim(),
      lot_id: hash_id,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/broadcast-message`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Message sent successfully:", response.data);
    } catch (error) {
      // console.error("Error sending message:", error.response?.data || error.message);
      toast.error("Failed to send message. Please try again.");
    }
    setMessage("");
  };

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setIsChatVisible(true)
    }
  }, []);

  return (
    <>
      <Viewer />
      {!isChatVisible ? (
        <div ></div>
      ) : (

        <div className="chat-message">
          <div id="chat-part">
            <div id="chat-container" className="relative" ref={chatContainerRef}>
              {Array.isArray(messages) &&
                messages.map((msg) => (
                  <div
                    className={`message ${msg.username === currentUser ? "user" : "other"} border rounded px-2 py-2 w-50`}
                    key={msg.id}
                  >
                    <div className="username">
                      <b className="capitalize">{msg.username}</b>
                      <small className="timestamp">
                        {moment(msg.created_at).format("D MMM YYYY, HH:mm:ss A")}
                      </small>
                    </div>
                    <div className="message-content">
                      <span>{msg.message}</span>
                    </div>
                  </div>
                ))}
            </div>
            <form onSubmit={sendMessage} className="px-[25px] py-[5px]">
              <input
                className="message-input"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter message"
                required
              />
              <input type="submit" value="Send" className="send-message" />
            </form>
          </div>
        </div>
      )}
      {/* <Rechat hashId={hashId} /> */}
    </>
  );
};

export default Chat;
